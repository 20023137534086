export const supportedFeatureFlagNames = [
  'deductionsEnabled',
  'expiryHubEnabled',
  'incidentsEnabled',
  'locationsAndRegionsEnabled',
  'locationEditingEnabled',
  'instantPayPayrun',
  'signedPoliciesReportEnabled',
  'schedulingEnabled',
  'thirdPartyDeductionsEnabled',
  'worklogEnabled',
] as const
